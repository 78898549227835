@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://rawgit.com/LeshikJanz/libraries/master/Bootstrap/baguetteBox.min.css');

:root {
    --titleFont: "Inter", sans-serif;
    --bodyFont: "Inter", sans-serif;
    --titleColor: #000000;
    --titleColorSpn: #EC2165;
    --contentColor: #000000;
    --h1FSize: 70px;
    --h2FSize: 36px;
    --h3FSize: 30px;
    --h4FSize: 24px;
    --h5FSize: 18px;
    --h6FSize: 14px;
    --btnBg: #85D5EE;
    --btnColor: #fff;
    --btnBgHover: #EC2165;
    --btnColorHover: #fff;
    --btnPaddig: 8px 20px 8px 20px;
    --btnFsize:14px;
    --wrapperWidth:1400px;
    --contentFontSize:16px;
    --titleBottomFsize:18px;
    --contentFontSizeSmall:14px;
    --contentFontSizeVSmall:12px;
    --footerHeaderColor:#85D5EE;
    --footerLinkColor:#444444;
    --footerLinkHoverColor:#EC2165;
    --footerBg:#F3F4F6;
    --light-bg:#ffffff;
    --faq-item-bg: #85D5EE;
    --faq-item-bg-hover: #EC2165;
    --faq-item-border-color: #ec216552;

    @media only screen and (mx-width: 1024px) {
      --h1FSize: 45px;
      --h2FSize: 26px;
      --h3FSize: 20px;
      --h4FSize: 18px;
      --h5FSize: 14px;
      --h6FSize: 12px;

    }
    @media only screen and (max-width: 768px) {
      --h1FSize: 26px;
      --h2FSize: 20px;
      --h3FSize: 18px;
      --h4FSize: 16px;
      --h5FSize: 14px;
      --h6FSize: 12px;
      --contentFontSize: 14px;
      --btnFsize:13px;

    }


  }


p,div,a{font-family: var(--bodyFont);color: var(--contentColor); font-size: var(--contentFontSize);}
h1, h2, h3, h4, h5, h6{font-family: var(--titleFont); color: var(--titleColor);}
h1{font-size: var(--h1FSize);}
h2{font-size: var(--h2FSize);}
h3{font-size: var(--h3FSize);}
h4{font-size: var(--h4FSize);}
h5{font-size: var(--h5FSize);}
h6{font-size: var(--h6FSize);}
.wrapper{margin: 0 auto;width: var(--wrapperWidth);max-width: 98%;}
.btn-block{display: block; width: 100%;}
.main-header {padding: 15px 0;box-shadow: 0 0 10px #cccccc57;}
.main-header .container{display: flex;}
.main-header .main-nav{margin:0 0 0 auto;padding: 0;}
.main-header .phone-no-box {padding:0;margin: 0 0 0 40px;}
.main-header .phone-no-box p {margin: 0;background: #85D5EE;border-radius: 8px;text-align: center;padding: 10px 15px;font-size:var(--contentFontSizeSmall);color: #111827;}
.main-header .phone-no-box .whatsapp-link{font-size:var(--contentFontSizeSmall);color: #111827;text-decoration: none;}
.main-header .phone-no-box .whatsapp-link:hover{color: #fff;}
.theam-btn-m{color: var(--btnColor);background: var(--btnBg);font-size:var(--btnFsize) ;margin: 5px;}
.theam-btn-m:hover{color: var(--btnColorHover);background: var(--btnBgHover);}
.mw-200{min-width: 200px;}
.mw-60{min-width: 60%;padding: 15px 0;}


span.carousel-control-next-icon,span.carousel-control-prev-icon {
  background-color: #00000093;
  border-radius: 4px;
}



/*Hero Banner style*/
.home-hero-banner .carousel-inner .carousel-item img {width: 100%;}
.home-hero-banner .carousel-inner .carousel-item .carousel-caption h1{font-weight: 700;margin-bottom: 30px;}
.home-hero-banner .carousel-inner .carousel-item .carousel-caption h1 span{color: var(--titleColorSpn);}
.home-hero-banner .carousel-inner .carousel-item .carousel-caption p{margin-top: 20px;}
.home-hero-banner .carousel-inner .carousel-item .carousel-caption .hero-btn-box {background: #fff;border-radius: 10px;}


/*specialized Section Style*/
.specialized-section{text-align: center;margin: 80px 0;}
.specialized-section h2{font-weight: 700;margin-bottom: 20px;}
.specialized-section h2 span{color: var(--titleColorSpn);}
.specialized-section p.limit-width{width: 700px;max-width: 100%;margin:0 auto 40px auto;font-size: var(--titleBottomFsize);}
.specialized-section h5{font-weight: 500;margin:20px 0; text-align: center;}
.specialized-section a{text-decoration: none;display: block;}
.specialized-section a h5{text-decoration: none;color: var(--titleColorSpn);}
.specialized-section .articles-item{padding:20px 15px;}
.specialized-section .articles-item .shadow-box{transition: 0.3s;padding-bottom: 10px;border-radius: 35px;overflow: hidden;background: #F6F5F1;border: 1px solid #fff;}
.specialized-section .articles-item .shadow-box:hover{box-shadow: 0 0 10px #ccc;}

.specialized-section .articles-item .shadow-box img{width: 100%;}

/*Our Team Section Style*/
.ourteam-section{text-align: left;margin: 80px 0;}
.ourteam-section h2{font-weight: 700;margin-bottom: 20px;text-align: center;}
.ourteam-section h2 span{color: var(--titleColorSpn);}
.ourteam-section p.limit-width{width: 700px;max-width: 100%;margin:0 auto 40px auto;text-align: center;font-size: var(--titleBottomFsize);}
.ourteam-section h5{font-weight: 500;margin:15px 0 5px 0; text-align: left;text-transform: uppercase;}
.ourteam-section .carousel-item .col-md-6{padding:20px 32px;}
.ourteam-section .carousel-item .shadow-box{box-shadow: 0 0 10px #ccc;padding-bottom: 10px;border-radius: 26px;overflow: hidden;background: #F9FAFB;padding: 20px 10px;border: 1px solid #fff;}
.ourteam-section .ext-content span.exp-yers {background: #DCFCE7;font-size: var(--contentFontSizeVSmall);color: #15803D;padding: 3px 7px;border-radius: 10px; display: inline-block;margin-right: 10px;}
.ourteam-section .ext-content span.mst-in {background: #DBEAFE;font-size: var(--contentFontSizeVSmall);color: #1D4ED8;padding: 3px 7px;border-radius: 10px;display: inline-block;}
.ourteam-section .carousel-item .shadow-box .ext-content {margin-bottom: 15px;}



/*What Our Patients Have To Say Section Style*/
.ourtestimonials-section{text-align: left;margin: 80px 0;}
.ourtestimonials-section h2{font-weight: 700;margin-bottom: 20px;text-align: center;}
.ourtestimonials-section h2 span{color: var(--titleColorSpn);}
.ourtestimonials-section p.limit-width{width: 700px;max-width: 100%;margin:0 auto 40px auto;text-align: center;font-size: var(--titleBottomFsize);}
.ourtestimonials-section h5{font-weight: 500;margin:15px 0 5px 0; text-align: left;}
.ourtestimonials-section .carousel-item .col-md-6{padding:20px 32px;}
.ourtestimonials-section .carousel-item .shadow-box{padding-bottom: 10px;border-radius: 26px;overflow: hidden;background: #bebebe6b;padding: 20px 10px;border: 1px solid #fff;}
.ourtestimonials-section .ext-content span.exp-yers {background: #DCFCE7;font-size: var(--contentFontSizeVSmall);color: #15803D;padding: 3px 7px;border-radius: 10px; display: inline-block;margin-right: 10px;}
.ourtestimonials-section .ext-content span.mst-in {background: #DBEAFE;font-size: var(--contentFontSizeVSmall);color: #1D4ED8;padding: 3px 7px;border-radius: 10px;display: inline-block;}
.ourtestimonials-section .carousel-item .shadow-box .ext-content {margin-bottom: 15px;}



/*Associate Partners Section Style*/
.partners-section{text-align: left;margin: 80px 0;}
.partners-section h2{font-weight: 700;margin-bottom: 20px;text-align: center;}
.partners-section h2 span{color: var(--titleColorSpn);}
.partners-section p.limit-width{width: 700px;max-width: 100%;margin:0 auto 40px auto;text-align: center;font-size: var(--titleBottomFsize);}
.partners-section ul.partners-logo {list-style: none;display: flex;flex-wrap: wrap;justify-content: center;align-items: center;}
.partners-section ul.partners-logo li {margin: 15px;}

/*Read Our Latest Articles Section Style*/
.articles-section{text-align: center;margin: 80px 0;}
.articles-section h2{font-weight: 700;margin-bottom: 20px;}
.articles-section h2 span{color: var(--titleColorSpn);}
.articles-section p.limit-width{width: 700px;max-width: 100%;margin:0 auto 40px auto;font-size: var(--titleBottomFsize);}
.articles-section h5{font-weight: 500;margin:20px 0 10px 0; text-align: left;}
.articles-section a{text-decoration: none;display: block;}
.articles-section a h5{text-decoration: none;color: var(--contentColor);padding:0 15px;}
.articles-section a p{text-decoration: none;color: var(--contentColor); text-align: left;padding:0 15px;}
.articles-section .articles-item{padding-top:20px;padding-bottom:15px;padding-left: 15px; padding-right: 15px;}
.articles-section .articles-item .shadow-box{transition: 0.3s;padding-bottom: 10px;border-radius: 35px;overflow: hidden;background: #F6F5F1;border: 1px solid #fff;}
.articles-section .articles-item .shadow-box:hover{box-shadow: 0 0 10px #ccc;}
.articles-section .articles-item .shadow-box img.img-fluid {width: 100%;}


/*Subscribe Newsletter Section Style*/
.newsletters-section{text-align: left;margin: 80px 0;border-top: 1px solid #D9D9D9;padding-top: 40px;}
.newsletters-section h2{font-weight: 700;margin-bottom: 20px;text-align: center;}
.newsletters-section h2 span{color: var(--titleColorSpn);}
.newsletters-section p.limit-width{width: 700px;max-width: 100%;margin:0 auto 40px auto;text-align: center;font-size: var(--titleBottomFsize);}
.newsletters-section .newsletterForm {max-width: 100%;width: 500px;margin: 0 auto;border: 1px solid #fff;box-shadow: 0 0 15px #ccc;border-radius: 14px;padding: 5px;background: #F6F5F1;}
.newsletters-section .newsletterForm input {height: 42px;width: calc(100% - 104px);border: 0;background: none;}
.newsletters-section .newsletterForm input:focus-visible{border: 0 !important;background: none;outline: none !important;}


.articles-main .articles-item{padding: 10px;}

.slick-prev:before, .slick-next:before {font-size: 40px !important;color: #85D5EE !important;}
.slick-prev {left: -45px !important;}

.main-footer{background-color:#F3F4F6 ;padding: 50px 0 0;}
.main-footer h6{color: var(--footerHeaderColor);}
.main-footer a{color: var(--footerLinkColor);text-decoration: none;}
.main-footer a:hover{color: var(--footerLinkHoverColor);}
.main-footer ul{margin: 0;padding: 0;list-style: none;}
.main-footer ul li{margin:0 0 10px 0;padding: 0;list-style: none;}
.main-footer .social-media a{display: inline-block;width: 40px;height: 40px;line-height: 40px;text-align: center;border:1px solid #fff;margin-left: 10px;box-shadow: 0 0 5px #ccc;border-radius: 50%;}
.main-footer .copy-right{background-color:var(--light-bg);padding: 20px 0;}




.v-align-middle{display: flex;align-items: center;justify-content: center;}
/*Services Items Style*/
.clinic-visit{margin: 80px 0;width: 100%;}
.our-services{margin: 80px 0;width: 100%;}
.services-item .services-item-content{width: 100%;text-align: center;padding: 15px;}
.services-item h2{font-weight: 700;margin-bottom: 20px;text-align: center;}
.services-item h2 span{color: var(--titleColorSpn);}
.services-item p{margin:0 auto 40px auto;text-align: center;font-size: var(--titleBottomFsize);}
.services-item .col-lg-6.col-md-6.col-sm-12 img{min-width: 100%;}

.clinic-visit .services-item-content h3{font-weight: 700;margin-bottom: 20px;text-align: center;}
.clinic-visit .services-item-content h3 span{color: var(--titleColorSpn);}
.clinic-visit .working-time-table-box {background: #e2f0f1;}
.clinic-visit table.working-time-table {border: 1px solid #fff;width: 100%;}
.clinic-visit table.working-time-table tr{border: 1px solid #fff;}
.clinic-visit table.working-time-table tr td{padding: 5px 10px;}




/*Payment Form*/
.payu-payment-form div label {width: 100%;margin-bottom: 10px;}
.payu-payment-form div input {width: 100%;border: 1px solid #ccc;padding: 5px 10px;height: 45px;border-radius: 8px;margin-bottom: 20px;}
.payu-payment-form button.btn.btn-primary {width: 100%;margin-top: 30px !important;display: block;min-height: 50px;font-size: 20px;font-weight: 500;text-transform: uppercase;background: #dd3869;border: 1px solid #dd3869;}
.payu-payment-form button.btn.btn-primary:hover {background: #ca2f5e;border: 1px solid #ca2f5e;}

/*Gallery page*/
.gallery-container p.page-description {
  text-align: center;
  margin: 25px auto;
  font-size: 18px;
  color: #999;
}

.tz-gallery {
  padding: 40px;
}
.gallery-container h2{font-weight: 700;margin-bottom: 20px;text-align: center;}
.gallery-container h2 span{color: var(--titleColorSpn);}
/* Override bootstrap column paddings */


.container.gallery-container {
  background-color: #fff;
  color: #35373a;
  min-height: 100vh;
  padding: 30px 50px;
}
.tz-gallery .row > div {
  padding: 2px;
}
.tz-gallery .lightbox{position: relative;display: block;}
.tz-gallery .lightbox img {
  width: 100%;
  border-radius: 0;
  position: relative;
}

.tz-gallery .lightbox:before {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -13px;
  margin-left: -13px;
  opacity: 0;
  color: #fff;
  font-size: 26px;
  
  content: "⚲";
  pointer-events: none;
  z-index: 9000;
  transition: 0.4s;
  -moz-transform: rotate(-45deg); 				
  -o-transform: rotate(-45deg); 				
  transform: rotate(-45deg); 
}


.tz-gallery .lightbox:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color:#ec216577;
  content: '';
  transition: 0.4s;
}
.tz-gallery .lightbox:hover:after,
.tz-gallery .lightbox:hover:before {
  opacity: 1;
}

.baguetteBox-button {
  background-color: transparent !important;
}

@media(max-width: 768px) {
  body {
      padding: 0;
  }
}













@media only screen and (max-width: 768px) {
.services-item .mob-order-2{order: 2;}
.services-item .mob-order-1{order: 1;}
}

.Inner-banner{width: 100%; text-align: center;}
.Inner-banner img{width: 100%;}
/*about us page Style*/
.aboutus-page{margin: 80px 0;width: 100%;}
.aboutus-page .about-content .col-sm-12{margin-bottom: 20px;}
.aboutus-page .about-content .col-sm-12 .card{height: 100%;border-color:  var(--titleColorSpn);}
.aboutus-page .about-content .col-sm-12 .card .card-number {padding: 15px 0 0 15px;font-size: 30px;font-weight: 700;color: var(--titleColorSpn);}








/*faq page Style*/

.card {
  box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3); 
 border-radius: 8px;
 font-size: 13px;
 font-weight: 500;
 letter-spacing: 0.3px;
 color:var(--contentColor);
 overflow: hidden;
}
.rc-accordion-toggle {
 display: flex;
 align-items: flex-start;
 cursor: pointer;
 justify-content: space-between;
 background-color:var(--footerBg);
 transition: 0.3s;
}
.rc-accordion-toggle.active{
 background-color: var(--faq-item-bg-hover);
}
.rc-accordion-toggle.active .rc-accordion-icon{
 transform: rotate(180deg);
 color: #fff;
}
.rc-accordion-card{
 border: 1px solid var(--faq-item-border-color);
 border-radius: 5px;
 margin-bottom: 10px;
 overflow: hidden;
}
.rc-accordion-card:last-child{
 margin-bottom: 0;
}
.rc-accordion-title{
 font-weight: 500;
 font-size: 14px;
 position: relative;
 margin-bottom: 0;
 color: #475F7B;
 transition: 0.3s;
}
.rc-accordion-toggle.active .rc-accordion-title{
 color: #fff;
}
.rc-accordion-icon{
 position: relative;
 top: 2px;
 color: #475F7B;
 transition: 0.35s;
 font-size: 12px;
}
.rc-accordion-body{
 flex: 1 1 auto;
 min-height: 1px;
 padding: 15px;
}
.rc-accordion-body p{
 margin-bottom: 0;
 font-size: 14px;
 font-weight: 400;
 line-height: 24px;
 color: #727E8C;
}
.rc-collapse{
 position: relative;
 height: 0;
 overflow: hidden;
 transition: height 0.35s ease;
}
.rc-collapse.show{
 height: auto;
}


/*contact Us Page and Book Apointment */
.contact-address-section {
  background: #fbfbfb;
  padding: 3%;
  box-shadow: 0 0 15px #ccc;
  border-radius: 20px;
}
.inquery-form{padding-right: 40px;}
.contact-address-section .social-media a {
  margin-right: 20px;
}
.inquery-form .form-group > div {
  margin-bottom: 15px;
}

.bookapointment .form-group .col-lg-6.col-md-6.col-sm-12 {
  margin-bottom: 15px;
}
.bookapointment .col-lg-7.col-md-7.col-sm-12.bookapointment-form {
  background: #f5f5f5;
  box-shadow: 0 0 15px #ccc;
  border-radius: 20px;
  padding: 20px;
}
.bookapointment .col-lg-5.col-md-5.col-sm-12.img-section {
  display: flex;
  align-items: center;
  padding: 20px;
}
.bookapointment .col-lg-5.col-md-5.col-sm-12.img-section img{border-radius: 20px;}



.bookapointment h2,.contact-page h2{font-weight: 700;margin-bottom: 20px;text-align: center;}
.bookapointment h2 span,.contact-page h2 span{color: var(--titleColorSpn);}


@media only screen and (min-width: 768px) {
    .home-hero-banner .carousel-inner .carousel-item .carousel-caption{width: 36%;text-align: left;right: 10%;left: 10%;}
    .margin-left-30{margin-left: 30%;}
  }

@media only screen and (max-width: 768px) {
  .home-hero-banner .carousel-caption h1 {
    background: #ffffffc7;
    border-radius: 10px;
    padding: 7px;
}
.home-hero-banner .carousel-inner .carousel-item .carousel-caption h1{margin-bottom: 10px;}
button.slick-arrow.slick-prev,button.slick-arrow.slick-next{display: none !important;}
.main-nav div#navbarSupportedContent {
  position: absolute;
        right: -300px;
        top: -15px;
        z-index: 99;
        background: #f9fafb;
        border: 1px solid #cbcbd9;
        min-width: 280px;
        padding: 15px;
        height: 100vh;
        opacity: 0;
        transition: right 1s, opacity 0.5s;
}
div#navbarSupportedContent.show {
  height: 100vh !important;
  right: -15px;
  opacity: 1;
  transition: right 1s, opacity 0.5s;
}
.navbar-nav li.nav-item {
  border-bottom: 1px solid #e8eaed;
}
.inquery-form{padding-right: 0;}
}
@media only screen and (max-width: 480px) {
  .home-hero-banner .carousel-inner .carousel-item .carousel-caption p,.main-header .phone-no-box {display: none;}
  .partners-section ul.partners-logo{padding-left: 0;}
  .partners-section ul.partners-logo li{margin: 4%;max-width: 41%;}
  .partners-section ul.partners-logo li img {max-width: 100%;}

}